<template>
  <CommonModal
    :visible="visible"
    :is-persistent="true"
    @close="close()"
  >
    <div class="mb-5 text-heading --w-bold --c-prm">
      {{ $t("common.cancelVacancyNotification") }}
    </div>
    <div>
      <v-btn
        block
        rounded
        x-large
        class="text-medium--w-bold py-6 mb-3"
        color="primary"
        @click="$emit('release')"
      >
        {{ $t("buttons.release") }}
      </v-btn>
      <v-btn
        block
        rounded
        x-large
        class="text-medium--w-bold py-6 --c-prm"
        @click="close()"
      >
        {{ $t("buttons.return") }}
      </v-btn>
    </div>
  </CommonModal>
</template>

<script>
import CommonModal from '@/components/Modal/Common'

export default {
  name: 'CancelNotificationModal',
  components: { CommonModal },
  props: {
    visible: Boolean,
    handleOperation: Function
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
