<template>
  <v-dialog content-class="bg-w-silver" v-model="isVisible" width="378">
    <div class="text-right">
      <button
        @click.stop="$emit('close')"
        class="float-right btn-close bg-primary flex-shrink-0"
      >
        <div class="btn-close__icon" />
      </button>
    </div>

    <p class="mt-15 text-center --prm text-title --w-bold">
      {{ $t("text.notRegistered.title") }}
    </p>
    <p class="pa-5 text-mediu --w-medium --medium-gray">
      {{ $t("text.notRegistered.text") }}
    </p>
    <div class="px-10">
      <v-btn
        elevation="0"
        @click="gotoSearch"
        block
        rounded
        class="bg-primary-light --white text-medium --w-bold mb-10 mt-3 py-5"
      >{{ $t("menu.main.vacancySearch") }}</v-btn
      >
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'NotRegistered',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    gotoSearch () {
      this.$router.push({
        name: 'home-vacancy-search'
      })
    }
  }
}
</script>
