<template>
  <div class="px-5 pb-15 vacancyNot">
    <!-- Vacancy notification list -->
    <h2 v-if="vacationList.length != 0" class="text-heading--prm py-8 text-center">
      {{ $t("vacancy.vacancyNotificationList") }}
    </h2>
    <VacancyNotiItem
      v-for="item in vacationList"
      :key="item.id"
      :id="item.id"
      :dateFrom="item.checkInDate"
      :dateTo="item.checkOutDate"
      :name="item.roomType.facility.name"
      :roomName="item.roomType.name"
      :pointName="item.roomType.facility.name"
      :notifiedAt="item.notifiedAt"
    />
    <!-- -------------------------------  -->

    <!-- Parking-lot List -->
    <h2
      v-if="parkingList.length != 0"
      class="text-heading--prm py-4 text-center"
    >
      {{ $t("vacancy.parkingLotRegistrationList") }}
    </h2>
    <VacancyNotiItem
      v-for="item in parkingList"
      :key="item.id + 'x'"
      :id="item.id"
      :dateFrom="item.fromDate"
      :dateTo="item.toDate"
      :name="item.facilityParking.facility.name"
      :typeVehicle="item.facilityParking.name"
      :heightLimit="item.facilityParking.maxHeight"
      :parking="true"
    />
    <!-- ----------- -->
    <NotRegistered :visible="isNotRegistered" @close="back()" />
  </div>
</template>

<script>
import VacancyNotiItem from '../../components/VacancyNotiItem'
import NotRegistered from './component/NotRegistered'

export default {
  name: 'VacancyNotification',
  components: {
    VacancyNotiItem,
    NotRegistered
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('waitLists', {
          apollo: this.$apollo
        })

        this.isNotRegistered = (this.vacationList.length + this.parkingList.length < 1)
      })
    })
  },
  computed: {
    vacationList () {
      return this.$store.state.waitlist.myCancelWaitList
    },
    parkingList () {
      return this.$store.state.waitlist.myFacilityParkingCancelWaitlistList
    }
  },
  methods: {
    back () {
      window.location = '/home'
    }
  },
  data () {
    return {
      isNotRegistered: false
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../../assets/styles/modules/variables-scss' as v;

@media (min-width: v.$breakpoint__small){
  .vacancyNot{
    background: white;
  }
}
</style>
