<template>
  <div class="bg-w-silver pa-5 mb-5">
    <div class="text-sub-title  font-weight-bold mb-4">
      {{ dateFrom | jaLongDate({ hideDow: true }) }}-{{ dateTo | jaLongDate({ hideDow: true }) }} ({{
        dateDiffInDays(dateFrom, dateTo)
      }}泊)
    </div>
    <v-divider class="bg-silver"/>
    <v-container>
      <v-row>
        <v-col md="4" cols="12" class="justify-center d-flex flex-column text-heading mt-4 mb-1">{{ name }}</v-col>
        <v-col md="4" cols="12" class="d-flex text-sub-title justify-center flex-column">
          <div>
            <template v-if="roomName">
              {{ roomName }} <label class="mx-3">/</label> {{ pointName }}
            </template>
            <template v-else>
              <span class="mr-3"> {{ typeVehicle }} </span>
              {{ heightLimit ? `(${$t('vacancy.vehicleHeightLimit')}${heightLimit}cm)` : '' }}
            </template>
          </div>
        </v-col>
        <v-col md="4" cols="12" class="text-center justify-center d-flex flex-column">
          <v-btn
            elevation="0"
            @click="isCancelModalVisible = true"
            :disabled="disabled"
            x-large
            rounded
            outlined
            class="px-10 vacancyNotBtn mt-8 mb-3">
            <div>
              <span>{{ btnText }}</span>
              <span v-if="notifiedAt" class="text-small"><br>送信日時：{{ notifiedAt | jaLongDateTime({ hideDow: true }) }}</span>
            </div>
          </v-btn>
          <CancelNotificationModal
            :visible="isCancelModalVisible"
            @close="isCancelModalVisible = false"
            @release="handleCancel(id); isCancelModalVisible = false"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CancelNotificationModal from './CancelNotificationModal'
import { dateDiffInDays } from '@/utils/get-date'

export default {
  name: 'VacancyNotiItem',
  components: { CancelNotificationModal },
  props: {
    id: [String, Number],
    dateFrom: String,
    dateTo: String,
    name: String,
    parking: {
      Boolean,
      default: false
    },
    roomName: String,
    pointName: String,
    typeVehicle: String,
    heightLimit: [String, Number],
    notifiedAt: [Date, String]
  },
  data () {
    return {
      isCancelModalVisible: false
    }
  },
  computed: {
    disabled () {
      return !!this.notifiedAt
    },
    btnText () {
      if (this.notifiedAt) {
        return this.$t('buttons.alreadyNotified')
      }
      return this.$t('buttons.cancelNotification')
    }
  },
  methods: {
    dateDiffInDays,
    async handleCancel (id) {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          if (!this.parking) {
            await this.$store.dispatch('CancelWaitList', {
              apollo: this.$apollo,
              id: id
            })
          } else {
            await this.$store.dispatch('CancelParkingWaitList', {
              apollo: this.$apollo,
              id: id
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../../assets/styles/modules/variables-scss' as v;

.vacancyNotBtn {
  background: var(--color__white);
  color: var(--color__primary);
}

</style>
